import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));

const StaffONSIBO = Loadable(
  lazy(() => import("views/application/customer/Staff/Staff"))
);
const BusinessUnits = Loadable(
  lazy(() => import("views/application/customer/BusinessUnits"))
);
// widget routing
// const WidgetStatistics = Loadable(lazy(() => import('views/widget/Statistics')));

// ==============================|| MAIN ROUTING ||============================== //

const DashboardRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/dashboard/staff",
      element: <StaffONSIBO />,
    },
    {
      path: "/dashboard/my-business",
      element: <BusinessUnits />,
    },
  ],
};

export default DashboardRoutes;
