// project imports
import services from 'utils/mockAdapter';

// user list
const dashboard = {
    id: 'dashboard',
    title: 'dashboard',
    icon: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'default',
            type: 'item',
            url: '/dashboard/default',
            icon: 'dashboard',
            breadcrumbs: false
        },
        {
            id: 'analytics',
            title: 'analytics',
            type: 'item',
            url: '/dashboard/proposals',
            icon: 'propos',
            breadcrumbs: false
        },
        {
            id: 'projects',
            title: 'projects',
            type: 'item',
            url: '/dashboard/projects',
            icon: 'box',
            breadcrumbs: false
        },
        {
            id: 'tasks',
            title: 'tasks',
            type: 'item',
            url: '/dashboard/tasks',
            icon: 'settings',
            breadcrumbs: false
        },
        {
            id: 'invoices',
            title: 'invoices',
            type: 'item',
            url: '/dashboard/invoices',
            icon: 'invoice',
            breadcrumbs: false
        },
        {
            id: 'expenses',
            title: 'expenses',
            type: 'item',
            url: '/dashboard/expenses',
            icon: 'outs',
            breadcrumbs: false
        },        
        {
            id: 'properties',
            title: 'properties',
            type: 'item',
            url: '/dashboard/properties',
            icon: 'proper',
            breadcrumbs: false
        },
        {
            id: 'products',
            title: 'products',
            type: 'item',
            url: '/dashboard/services',
            icon: 'svc',
            breadcrumbs: false
        },
        {
            id: 'accounts',
            title: 'accounts',
            type: 'item',
            url: '/dashboard/accounts',
            icon: 'account',
            breadcrumbs: false
        },
        {
            id: 'staff',
            title: 'staff',
            type: 'item',
            url: '/dashboard/staff',
            icon: 'staff',
            breadcrumbs: false
        },
        {
            id: 'leads',
            title: 'leads',
            type: 'item',
            url: '/dashboard/leads',
            icon: 'lead',
            breadcrumbs: false
        },
        {
            id: 'orders',
            title: 'orders',
            type: 'item',
            url: '/dashboard/orders',
            icon: 'orders',
            breadcrumbs: false
        },
        {
            id: 'mybusiness',
            title: 'mybusiness',
            type: 'item',
            url: '/dashboard/my-business',
            icon: 'mybusiness',
            breadcrumbs: false
        }
        
    ]
};

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/dashboard').reply(200, { dashboard });
