// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
// import axios from "axios";
import axios from "axios";

import { dispatch } from "../index";
import { FIREBASE_API } from "config";
import { API_URL } from "config";
import { FBGRAPH_URL } from "config";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  facebookToken: "",
  isLoading: false,
  channelsList: [],
  
  SelectedTokenPage:"",
  SelectedPageId:"",
  SelectedPageName:"",
  business_id:"",
  channel_type:""
};

const slice = createSlice({
  name: "add-channels",
  initialState,
  reducers: {
    setPagesList: (state, action) => {
      // console.log("ADDING TO STATE");
      // console.log(action.payload.facebookPagesList);
      let pagesList;
      if (action.payload) {
        // console.log("ADDING FROM SERVICE");
        pagesList = action.payload.facebookPagesList;
        localStorage.setItem("pagesList", JSON.stringify(pagesList));
      } else {
        let cachedPages = localStorage.getItem("pagesList");
        pagesList = JSON.parse(cachedPages);
        // console.log("ADDING FROM LOCAL STORAGE");
      }
      state.channelsList = pagesList;
    },
    setTokenToState: (state, action) => {
      state.facebookToken = action.payload.facebookToken;
      localStorage.setItem("facebookToken", action.payload.facebookToken);
    },
    setConnectedPageToBusinessSettings: (state, action) => {
      state.facebookToken = action.payload.facebookToken;
      localStorage.setItem("facebookToken", action.payload.facebookToken);
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addChannelsToList(data, action) {
  // console.log("Redux add channel");
  console.log(data);
  return async () => {
    try {
      var rpt = dispatch(slice.actions.setPagesList(data, action));
      // console.log("estado:",rpt);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setToken(data, action) {
  return async () => {
    try {
      var rpt = dispatch(slice.actions.setTokenToState(data, action));
      dispatch(slice.actions.setPagesList());
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearTokenAndPages(data, action) {
  return async () => {
    try {
      var rpt = dispatch(slice.actions.setTokenToState(data, action));
      dispatch(slice.actions.setPagesList());
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setConnectedPage(data, action) {
  return async () => {
    try {
      // /api/v0/meta/create

      /*
        token
        page_id
        page_name
        business_id
        channel_type
      */
      // const response = axios.post(API_URL+"api/v0/meta/create", {
      //   token:state.areaCode,
      //   page_id:state.sessionToken,
      //   page_name:state.businessName,
      //   business_id:"",
      //   channel_type:"messenger"
      // });

      var rpt = dispatch(slice.actions.setConnectedPageToBusinessSettings(data, action));
      dispatch(slice.actions.setPagesList());
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
