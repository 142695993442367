import { memo, useEffect } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Typography, useMediaQuery } from "@mui/material";

// project imports
import menuItem from "menu-items";
import NavGroup from "./NavGroup";
import useConfig from "hooks/useConfig";
import useAuth from "hooks/useAuth";
// import { DashboardMenu } from 'menu-items/dashboard';

import LAYOUT_CONST from "constant";
import { HORIZONTAL_MAX_ITEM } from "config";

// ==============================|| SIDEBAR MENU LIST ||============================== //

// const MenuList = () => {
//   const theme = useTheme();
//   const { layout } = useConfig();
//   const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
//   const { user } = useAuth();
//   const isAdmin = user?.is_admin ?? false;
//   const isTechnician = user?.is_technician ?? false;

//   // last menu-item to show in horizontal menu bar
//   const lastItem =
//     layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd
//       ? HORIZONTAL_MAX_ITEM
//       : null;

//   let lastItemIndex = menuItem.items.length - 1;
//   let remItems = [];
//   let lastItemId;

//   if (lastItem && lastItem < menuItem.items.length) {
//     lastItemId = menuItem.items[lastItem - 1].id;
//     lastItemIndex = lastItem - 1;
//     remItems = menuItem.items
//       .slice(lastItem - 1, menuItem.items.length)
//       .map((item) => ({
//         title: item.title,
//         elements: item.children,
//       }));
//   }
//   // AQUI SE FILTRAN LAS RUTAS ADMINISTRATIVAS SI NO ES ADMIN
//   const filteredItems =
//     isAdmin === false
//       ? {
//           items: menuItem.items.filter(
//             (item) => !item.id.includes("dash-admin")
//           ),
//         }
//       : menuItem;

//   const navItems = filteredItems.items
//     .slice(0, lastItemIndex + 1)
//     .map((item) => {
//       switch (item.type) {
//         case "group":
//           return (
//             <NavGroup
//               key={item.id}
//               item={item}
//               lastItem={lastItem}
//               remItems={remItems}
//               lastItemId={lastItemId}
//             />
//           );
//         default:
//           return (
//             <Typography key={item.id} variant="h6" color="error" align="center">
//               Menu Items Error
//             </Typography>
//           );
//       }
//     });

//   return <>{navItems}</>;
// };

// export default memo(MenuList);

// const MenuList = () => {
//   const theme = useTheme();
//   const { layout } = useConfig();
//   const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
//   const { user } = useAuth();

//   const isAdmin = user?.is_admin ?? false;
//   const isTechnician = user?.is_technician ?? false;
//   const isSuperAdmin = user?.is_super_admin ?? false;
//   const isProperty = user?.is_property ?? false;


  
//   // last menu-item to show in horizontal menu bar
//   const lastItem =
//     layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd
//       ? HORIZONTAL_MAX_ITEM
//       : null;

//   let lastItemIndex = menuItem.items.length - 1;
//   let remItems = [];
//   let lastItemId;

  
//   if (lastItem && lastItem < menuItem.items.length) {
//     lastItemId = menuItem.items[lastItem - 1].id;
//     lastItemIndex = lastItem - 1;
//     remItems = menuItem.items
//       .slice(lastItem - 1, menuItem.items.length)
//       .map((item) => ({
//         title: item.title,
//         elements: item.children,
//       }));
//   }

//   // Filtrar elementos basados en el tipo de usuario

//   let filteredItems = menuItem.items;
//   console.log(menuItem);
//   console.log("isAdmin", isAdmin);
//   console.log("isSuperAdmin", isSuperAdmin);
//   console.log("isProperty", isProperty);
//   console.log("isTechnician", isTechnician);
//   // dash-admin-properties
//   // dash-admin
//   // dash-super-admin

//   if (isAdmin) {
//     // si no es admin
//     filteredItems = filteredItems.filter(
//       //BUSINESS ADMIN
//       (item) => !item.id.includes("dash-admin")
//     );
//   }
//   if (isSuperAdmin) {
//     filteredItems = filteredItems.filter(
//       //si no es root, remover
//       (item) => !item.id.includes("dash-super-admin")
//     );
//   }

//   if (isProperty) {
//     filteredItems = filteredItems.filter(
//       //si no es root, remover
//       (item) => !item.id.includes("dash-super-admin")
//     );
//     filteredItems = filteredItems.filter(
//       //BUSINESS ADMIN
//       (item) => !item.id.includes("dash-admin")
//     );
//   }
//   if (isTechnician) {
//     filteredItems = filteredItems.filter(
//       //TECHNICIAN
//       (item) => !item.id.includes("dash-admin-properties")
//     );

//     filteredItems = filteredItems.filter(
//       //TECHNICIAN
//       (item) => !item.id.includes("dash-admin")
//     );

//     filteredItems = filteredItems.filter(
//       //PROPERTY USER
//       (item) => !item.id.includes("dash-admin-properties")
//     );
//   }

//   const navItems = filteredItems.slice(0, lastItemIndex + 1).map((item) => {
//     switch (item.type) {
//       case "group":
//         return (
//           <NavGroup
//             key={item.id}
//             item={item}
//             lastItem={lastItem}
//             remItems={remItems}
//             lastItemId={lastItemId}
//           />
//         );
//       default:
//         return (
//           <Typography key={item.id} variant="h6" color="error" align="center">
//             Menu Items Error
//           </Typography>
//         );
//     }
//   });

//   return <>{navItems}</>;
// };
// export default memo(MenuList);


// import React, { memo } from 'react';
// import { Typography } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import useConfig from 'path/to/useConfig';
// import useAuth from 'path/to/useAuth';
// import NavGroup from 'path/to/NavGroup';
// import { LAYOUT_CONST, HORIZONTAL_MAX_ITEM } from 'path/to/constants';
// import menuItem from 'path/to/menuItem';

const filterChildrenByRole = (group, role) => {
  const roleFilters = {
    isAdmin: {
      remove: ['rootadmin'],
      keep: ['staff','mybusiness','proposals','projects','tasks','invoices','expenses','properties','services','accounts','leads','orders']
    },
    isTechnician: {
      keep: ['orders', 'projects', 'tasks'],
      remove:['staff','mybusiness','proposals','invoices','expenses','properties','services','accounts','leads']
    },
    isProperty: {
      keep: ['proposals', 'invoices', 'orders'],
      remove: ['staff','mybusiness','projects','tasks','expenses','properties','services','accounts','leads']
    },
    isSuperAdmin: {
      remove: []
    }
  };

  const filter = roleFilters[role];
  
  return group.children.filter(child => {
    if (filter.keep) {
      return filter.keep.includes(child.id);
    }
    if (filter.remove) {
      return !filter.remove.includes(child.id);
    }
    return true;
  });
};

const filterItemsByRole = (items, role) => {
  return items.map(group => {
    const filteredChildren = filterChildrenByRole(group, role);
    const hasChildren = filteredChildren.length > 0;
    return {
      ...group,
      children: filteredChildren,      
      ...(hasChildren ? {} : { children: null })
    };
  }).filter(group => group.children !== null); // Filtrar grupos sin hijos
};

const MenuList = () => {
  const theme = useTheme();
  const { layout } = useConfig();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useAuth();

  const isAdmin = user?.is_admin ?? false;
  const isTechnician = user?.is_technician ?? false;
  const isSuperAdmin = user?.is_super_admin ?? false;
  const isProperty = user?.is_property ?? false;

  // last menu-item to show in horizontal menu bar
  const lastItem =
    layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd
      ? HORIZONTAL_MAX_ITEM
      : null;
  console.log(menuItem);
  let lastItemIndex = menuItem.items.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < menuItem.items.length) {
    lastItemId = menuItem.items[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = menuItem.items
      .slice(lastItem - 1, menuItem.items.length)
      .map((item) => ({
        title: item.title,
        elements: item.children,
      }));
  }

  // Filtrar elementos basados en el tipo de usuario
  let filteredItems = menuItem.items;
  
  if (isAdmin) {
    filteredItems = filterItemsByRole(filteredItems, 'isAdmin');
  }
  if (isSuperAdmin) {
    filteredItems = filterItemsByRole(filteredItems, 'isSuperAdmin');
  }
  if (isProperty) {
    filteredItems = filterItemsByRole(filteredItems, 'isProperty');
  }
  if (isTechnician) {
    filteredItems = filterItemsByRole(filteredItems, 'isTechnician');
  }

  const navItems = filteredItems.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            key={item.id}
            item={item}
            lastItem={lastItem}
            remItems={remItems}
            lastItemId={lastItemId}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default memo(MenuList);

