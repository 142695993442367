// import { lazy } from 'react';
// import { useRoutes } from 'react-router-dom';

// // routes
// import MainRoutes from './MainRoutes';
// import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
// import Loadable from 'ui-component/Loadable';

// const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));

// // ==============================|| ROUTING RENDER ||============================== //

// export default function ThemeRoutes() {
//     return useRoutes([{ path: '/', element: <PagesLanding /> }, AuthenticationRoutes, LoginRoutes, MainRoutes]);
// }


import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import MainLayout from 'layout/MainLayout';

import MainRoutes from './MainRoutes';
// import FreeRoutes from './FreeRoutes';
import DashboardRoutes from './DashboardRoutes';

import LoginRoutes from './LoginRoutes';
// import AuthenticationRoutes from './AuthenticationRoutes';
import Loadable from 'ui-component/Loadable';
import useAuth from 'hooks/useAuth';


// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { user } = useAuth();
    
    const isAdmin = user?.is_admin ?? false;
    // const isProperty = user?.is_property ?? false;
    // const isTechnician = user?.is_technician ?? false;
    // const isSuperAdmin = user?.is_super_admin ?? false;

    let routes = [
        { path: '/', element: <PagesLanding /> },
        AuthenticationRoutes, LoginRoutes, MainRoutes,PagesLanding
    ];

    if (isAdmin) {
        routes.unshift(DashboardRoutes);
    }

  

    return useRoutes(routes);
}


