// import widget from './widget';
// import application from './application';
// import forms from './forms';
// import elements from './elements';
// import pages from './pages';
// import utilities from './utilities';
// import support from './support';
// import other from './other';

// // ==============================|| MENU ITEMS ||============================== //

// const menuItems = {
//     items: [
//         // widget,  
//         // application, #TODO PENDING TO DO ROUTES 
//         //  forms, 
//         //  elements, 
//         //  pages, 
//         //  utilities, 
//         //  support,
//         //  other
//         ],
// };

// export default menuItems;
// // 7,9,18,3,12 = 49  -->69

import adminBusiness from './adminBusiness';
import application from './application';
import dashboard_admin from './dashboard-admin'
// const { user } = useAuth();
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        adminBusiness,  //Opciones para negocios (completo)
        application,
        dashboard_admin
        ],
};

export default menuItems;
