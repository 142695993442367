import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { memo } from "react";
import Lottie from "react-lottie-player";
// import { useHistory } from 'react-router-dom';
// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  Avatar,
  Card,
  CardContent,
  Link,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  linearProgressClasses,
  Box,
  ListItemButton,
  Button,
  ListItemIcon,
  Popover,
  Divider,
  MenuItem,
} from "@mui/material";

// assets
// import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SavingsIcon from "@mui/icons-material/Savings";
import Storefront from "@mui/icons-material/Storefront";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from '@mui/icons-material/Settings';
import useAuth from "hooks/useAuth";
import lottieJson from "assets/images/animations/storefront_onsibo.json";
// styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 30,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.dark.light : "#fff",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.primary.dark
        : theme.palette.primary.main,
  },
}));

const CardStyle = styled(Card)(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? theme.palette.dark.main
      : theme.palette.primary.light,
  marginBottom: "22px",
  overflow: "hidden",
  position: "relative",
  //   borderStyle: "solid",
  //   borderWidth: "0.5px",
  //   borderColor: "gray",
}));

const styles = {
  cardcontent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
};

const CardStyleSecondary = styled(Card)(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? theme.palette.dark.main
      : theme.palette.primary.light,
  marginBottom: "22px",
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "157px",
    height: "157px",
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.dark
        : theme.palette.primary[200],
    borderRadius: "50%",
    top: "-105px",
    right: "-96px",
  },
}));

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ value, ...others }) {
  const theme = useTheme();

  return (
    <Grid container direction="column" spacing={1} sx={{ mt: 1.5 }}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.dark.light
                    : theme.palette.primary[800],
              }}
            >
              Saldo de referidos
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="inherit">{`$${Math.round(
              value
            )}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <BorderLinearProgress variant="determinate" value={value} {...others} />
      </Grid>
    </Grid>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number,
};

// ==============================|| SIDEBAR - MENU CARD ||============================== //

const MenuCard = () => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [activeBusiness, setActiveBusiness] = useState([]);
  // const history = useHistory();
  const { user } = useAuth();
  useEffect(() => {
    console.log("Executing onload");
    console.log(activeBusiness);
    if (activeBusiness.length == 0 || activeBusiness == undefined) {
      console.log("No active business - auto active business");
      let allCommerces = user.businessData;
      console.log(allCommerces);
      if (allCommerces != []) {
        setActiveBusiness(user.businessData[0]);
        console.log("setting first as active commerce", activeBusiness);
      }
      // localStorage.setItem("ac", JSON.stringify(activeBusiness));
    } else {
      console.log("aut-active business");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("ac", JSON.stringify(activeBusiness));
  }, [activeBusiness]);

  const getLastBusinessFromAPI = () => {};

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleClickCreate = (event) => {
    // history.push('/on-boarding');
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);
  };

  const handleMenuItemClick = (option) => {
    console.log(option);
    handleClose();
  };

  // const retrieveActiveCommerce = () => {
  //   setActiveCommerce(JSON.parse(localStorage.getItem("ac")));
  // };
  const BusinessCardItem = ({ option }) => {
    return (
      <ListItem
        alignItems="flex-start"
        disableGutters
        sx={{ p: 0, maxWidth: 300, width: 226, cursor: "pointer" }}
        onClick={(o) => setActiveBusiness(option)}
      >
        <ListItemAvatar sx={{ mt: 1, ml: 1 }}>
          <Avatar
            sx={{ bgcolor: "white" }}
            src={"https://www.svgrepo.com/show/417140/store.svg"}
          ></Avatar>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            variant: "caption",
            style: {
              fontWeight: "bold",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
              overflow: "hidden",
              width: "90%",
              maxHeight: 20,
              height: 20,
              marginTop: 2,
              marginRight: 10,
            },
          }}
          primary={option.business_name}
          secondary={
            <Box>
              <Typography variant="caption">{option.role[0]?.name}</Typography>
            </Box>
          }
          secondaryTypographyProps={{
            variant: "caption",
          }}
        />
      </ListItem>
    );
  };

  return (
    <div>
      {activeBusiness && activeBusiness.role && activeBusiness.role[0] ? (
        <CardStyle variant="outlined">
          <CardContent
            sx={{ p: 1, cursor: "pointer", "&:last-child": { pb: 1 } }}
            onClick={handleClick}
          >
            <List sx={{ p: 0, m: 0 }}>
              <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                <ListItemAvatar sx={{ mt: 1, ml: 1 }}>
                  <Avatar
                    sx={{ bgcolor: "white" }}
                    src={"https://firebasestorage.googleapis.com/v0/b/onsibo-crm.appspot.com/o/onsibos.svg?alt=media&token=476d3202-bdd0-4cd8-ad67-8ea699b145f2"}
                  >
                    {/* <Storefront fontSize="inherit" /> */}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  // sx={{ mt: 0 }}
                  primaryTypographyProps={{
                    variant: "caption",
                    style: {
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                      maxHeight: 40,
                      height: "auto",
                      verticalAlign: "center",
                      display: "flex",
                      justifyContent: "left",
                      // alignItems:'center'
                    },
                  }}
                  primary={activeBusiness.business_name}
                  secondary={
                    <Box>
                      <Typography variant="caption">
                        {activeBusiness?.role[0]?.name}
                      </Typography>
                    </Box>
                  }
                  secondaryTypographyProps={{
                    variant: "caption",
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                      maxHeight: 40,
                      verticalAlign: "center",
                      display: "flex",
                      justifyContent: "left",
                    },
                  }}
                />
                <ListItemIcon>
                  {isPopoverOpen ? <ExpandLess /> : <ExpandMore />}{" "}
                </ListItemIcon>
              </ListItem>
              <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                {user?.businessData?.map((option, index) => (
                  <BusinessCardItem key={index} option={option} />
                ))}
              </Popover>
            </List>
          </CardContent>

          <Divider
          sx={{marginLeft:2, marginRight:2,marginTop:-1}}
          />

          <Button
                fullWidth
                variant="text"
                component={Link}
                href={"/my-business"}                 
              >
                <Typography
                  variant="caption"
                  // color={"white"}
                  color={theme.palette.mode === 'dark' ? "white" : "black"}
                  sx={{ textTransform: "none" }}
                >
                  Settings
                </Typography>
                <SettingsIcon sx={{ width: 18, height: 18, marginLeft: 1 }} />
              </Button>
          
          <Divider
          sx={{marginLeft:2, marginRight:2}}
          />
              <Button
                fullWidth
                variant="contained"
                component={Link}
                href={"/my-business"}
              >
                <Typography
                  variant="caption"
                  color={"white"}
                  sx={{ textTransform: "none" }}
                >
                  New business unit
                </Typography>
                <AddIcon sx={{ width: 18, height: 18, marginLeft: 1 }} />
              </Button>
            
        </CardStyle>
      ) : (
        <CardStyle variant="outlined">
          <CardContent
            sx={{ p: 1, cursor: "pointer", "&:last-child": { pb: 1 } }}
            onClick={handleClickCreate}
          >
            <List sx={{ p: 0, m: 0 }}>
              <ListItem
                alignItems="flex-start"
                component={Link}
                href={"/my-business"}
                disableGutters
                sx={{ p: 0, alignItems: "center" }}
              >
                <ListItemAvatar sx={{ mt: 0, ml: 0 }}>
                  <Lottie
                    loop
                    animationData={lottieJson}
                    play
                    style={{ width: 55, height: 55 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  // sx={{ mt: 0 }}
                  primaryTypographyProps={{
                    variant: "body1",
                    style: {
                      textDecoration: "none",
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                  primary="New business unit"
                />
                <ListItemIcon sx={{ mt: 0 }}>
                  <AddIcon />
                </ListItemIcon>
              </ListItem>
            </List>
          </CardContent>
        </CardStyle>
      )}

     
    </div>
  );
};

export default memo(MenuCard);
