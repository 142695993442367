// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconChartArcs, IconClipboardList, IconChartInfographic, IconHomeDot, IconUser, IconBox,IconUserCircle, } from '@tabler/icons';
import SettingsIcon from "@mui/icons-material/Settings";
// constant
const icons = {
    IconChartArcs,
    IconClipboardList,
    IconChartInfographic,
    IconHomeDot,
    IconUser,
    IconBox
};




// ==============================|| WIDGET MENU ITEMS ||============================== //

const adminBusiness = {
    id: 'dash-admin',
    title: <FormattedMessage id="dash-admin" />,
    icon: icons.IconChartArcs,
    type: 'group',
    children: [
        {
            id: 'staff',
            title: <FormattedMessage id="staff" />,
            type: 'item',            
            url: '/dashboard/staff',
            icon: IconUserCircle
        },
        {
            id: 'mybusiness',
            title: <FormattedMessage id="mybusiness" />,
            type: 'item',            
            url: '/dashboard/my-business',
            icon: SettingsIcon
        },
    ]
};

export default adminBusiness;
