// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconChartArcs,
  IconClipboardList,
  IconChartInfographic,
  IconHomeDot,
  IconUser,
  IconBox,
  IconUserCircle,
} from "@tabler/icons";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// constant
const icons = {
  IconChartArcs,
  IconClipboardList,
  IconChartInfographic,
  IconHomeDot,
  IconUser,
  IconBox,
};

// ==============================|| WIDGET MENU ITEMS ||============================== //

const widget = {
  id: "dash-super-admin",
  title: <FormattedMessage id="dash-super-admin" />,
  icon: icons.IconChartArcs,
  type: "group",
  children: [
    {
      id: "rootadmin",
      title: <FormattedMessage id="rootadmin" />,
      type: "item",
      url: "/spadmin/business",
      icon: AdminPanelSettingsIcon,
    },
  ],
};

export default widget;
