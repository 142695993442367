import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
const DashboardAnalytics = Loadable(
  lazy(() => import("views/dashboard/Analytics"))
);

// widget routing
const WidgetStatistics = Loadable(
  lazy(() => import("views/widget/Statistics"))
);
const WidgetData = Loadable(lazy(() => import("views/widget/Data")));
const WidgetChart = Loadable(lazy(() => import("views/widget/Chart")));

// application - user social & account profile routing
const AppUserSocialProfile = Loadable(
  lazy(() => import("views/application/users/social-profile"))
);
const AppUserAccountProfile1 = Loadable(
  lazy(() => import("views/application/users/account-profile/Profile1"))
);
const AppUserAccountProfile2 = Loadable(
  lazy(() => import("views/application/users/account-profile/Profile2"))
);
const AppUserAccountProfile3 = Loadable(
  lazy(() => import("views/application/users/account-profile/Profile3"))
);

// application - user cards & list variant routing
const AppProfileCardStyle1 = Loadable(
  lazy(() => import("views/application/users/card/CardStyle1"))
);
const AppProfileCardStyle2 = Loadable(
  lazy(() => import("views/application/users/card/CardStyle2"))
);
const AppProfileCardStyle3 = Loadable(
  lazy(() => import("views/application/users/card/CardStyle3"))
);
const AppProfileListStyle1 = Loadable(
  lazy(() => import("views/application/users/list/Style1"))
);
const AppProfileListStyle2 = Loadable(
  lazy(() => import("views/application/users/list/Style2"))
);

// application - customer routing
const AppCustomerList = Loadable(
  lazy(() => import("views/application/customer/CustomerList"))
);
const AppCustomerOrderList = Loadable(
  lazy(() => import("views/application/customer/Proposals/Proposals"))
);

const ViewProposalInternal = Loadable(
  lazy(() => import("views/application/customer/Proposals/ViewProposal"))
);

const ViewInvoicesInternal = Loadable(
  lazy(() => import("views/application/customer/Invoices/ViewInvoice"))
);

// const ViewProposalInternal = () => {
//   const { id } = useParams(); // Obtiene el parámetro de la URL

//   // Usa el ID en tu componente como desees
//   return <div>Proposal ID: {id}</div>;
// };

const ProjectsONSIBO = Loadable(
  lazy(() => import("views/application/customer/Projects/Projects"))
);

const TasksONSIBO = Loadable(
  lazy(() => import("views/application/customer/Tasks/Tasks"))
);

const InvoicesONSIBO = Loadable(
  lazy(() => import("views/application/customer/Invoices/Invoices"))
);

const ExpensesONSIBO = Loadable(
  lazy(() => import("views/application/customer/Expenses/Expenses"))
);

const PropertiesONSIBO = Loadable(
  lazy(() => import("views/application/customer/Properties/Properties"))
);

const ServicesONSIBO = Loadable(
  lazy(() => import("views/application/customer/Services/Services"))
);

const AccountsONSIBO = Loadable(
  lazy(() => import("views/application/customer/Accounts/Accounts"))
);


const LeadsONSIBO = Loadable(
  lazy(() => import("views/application/customer/Leads/Leads"))
);

const OrdersONSIBO = Loadable(
  lazy(() => import("views/application/customer/Orders/Orders"))
);

const AppCustomerCreateInvoice = Loadable(
  lazy(() => import("views/application/customer/CreateInvoice"))
);
const AppCustomerOrderDetails = Loadable(
  lazy(() => import("views/application/customer/OrderDetails"))
);
const AppCustomerProduct = Loadable(
  lazy(() => import("views/application/customer/Product"))
);
const AppCustomerProductReview = Loadable(
  lazy(() => import("views/application/customer/ProductReview"))
);

// application routing
const AppChat = Loadable(lazy(() => import("views/application/chat")));
const AppKanban = Loadable(lazy(() => import("views/application/kanban")));
const AppKanbanBacklogs = Loadable(
  lazy(() => import("views/application/kanban/Backlogs"))
);
const AppKanbanBoard = Loadable(
  lazy(() => import("views/application/kanban/Board"))
);
const AppMail = Loadable(lazy(() => import("views/application/mail")));
const AppCalendar = Loadable(lazy(() => import("views/application/calendar")));
const AppContactCard = Loadable(
  lazy(() => import("views/application/contact/Card"))
);
const AppContactList = Loadable(
  lazy(() => import("views/application/contact/List"))
);

// application e-commerce pages
const AppECommProducts = Loadable(
  lazy(() => import("views/application/e-commerce/Products"))
);
const AppECommProductDetails = Loadable(
  lazy(() => import("views/application/e-commerce/ProductDetails"))
);
const AppECommProductList = Loadable(
  lazy(() => import("views/application/e-commerce/ProductList"))
);
const AppECommCheckout = Loadable(
  lazy(() => import("views/application/e-commerce/Checkout"))
);

// forms component routing
const FrmComponentsTextfield = Loadable(
  lazy(() => import("views/forms/components/TextField"))
);
const FrmComponentsButton = Loadable(
  lazy(() => import("views/forms/components/Button"))
);
const FrmComponentsCheckbox = Loadable(
  lazy(() => import("views/forms/components/Checkbox"))
);
const FrmComponentsRadio = Loadable(
  lazy(() => import("views/forms/components/Radio"))
);
const FrmComponentsSwitch = Loadable(
  lazy(() => import("views/forms/components/Switch"))
);
const FrmComponentsAutoComplete = Loadable(
  lazy(() => import("views/forms/components/AutoComplete"))
);
const FrmComponentsSlider = Loadable(
  lazy(() => import("views/forms/components/Slider"))
);
const FrmComponentsDateTime = Loadable(
  lazy(() => import("views/forms/components/DateTime"))
);

// forms plugins layout
const FrmLayoutLayout = Loadable(
  lazy(() => import("views/forms/layouts/Layouts"))
);
const FrmLayoutMultiColumnForms = Loadable(
  lazy(() => import("views/forms/layouts/MultiColumnForms"))
);
const FrmLayoutActionBar = Loadable(
  lazy(() => import("views/forms/layouts/ActionBar"))
);
const FrmLayoutStickyActionBar = Loadable(
  lazy(() => import("views/forms/layouts/StickyActionBar"))
);

// forms plugins routing
const FrmAutocomplete = Loadable(
  lazy(() => import("views/forms/plugins/AutoComplete"))
);
const FrmMask = Loadable(lazy(() => import("views/forms/plugins/Mask")));
const FrmClipboard = Loadable(
  lazy(() => import("views/forms/plugins/Clipboard"))
);
const FrmRecaptcha = Loadable(
  lazy(() => import("views/forms/plugins/Recaptcha"))
);
const FrmWysiwugEditor = Loadable(
  lazy(() => import("views/forms/plugins/WysiwugEditor"))
);
const FrmModal = Loadable(lazy(() => import("views/forms/plugins/Modal")));
const FrmTooltip = Loadable(lazy(() => import("views/forms/plugins/Tooltip")));

// table routing
const TableBasic = Loadable(
  lazy(() => import("views/forms/tables/TableBasic"))
);
const TableDense = Loadable(
  lazy(() => import("views/forms/tables/TableDense"))
);
const TableEnhanced = Loadable(
  lazy(() => import("views/forms/tables/TableEnhanced"))
);
const TableData = Loadable(lazy(() => import("views/forms/tables/TableData")));
const TableCustomized = Loadable(
  lazy(() => import("views/forms/tables/TablesCustomized"))
);
const TableStickyHead = Loadable(
  lazy(() => import("views/forms/tables/TableStickyHead"))
);
const TableCollapsible = Loadable(
  lazy(() => import("views/forms/tables/TableCollapsible"))
);

// forms validation
const FrmFormsValidation = Loadable(
  lazy(() => import("views/forms/forms-validation"))
);
const FrmFormsWizard = Loadable(lazy(() => import("views/forms/forms-wizard")));

// chart routing
const ChartApexchart = Loadable(
  lazy(() => import("views/forms/chart/Apexchart"))
);
const OrgChartPage = Loadable(lazy(() => import("views/forms/chart/OrgChart")));

// basic ui-elements routing
const BasicUIAccordion = Loadable(
  lazy(() => import("views/ui-elements/basic/UIAccordion"))
);
const BasicUIAvatar = Loadable(
  lazy(() => import("views/ui-elements/basic/UIAvatar"))
);
const BasicUIBadges = Loadable(
  lazy(() => import("views/ui-elements/basic/UIBadges"))
);
const BasicUIBreadcrumb = Loadable(
  lazy(() => import("views/ui-elements/basic/UIBreadcrumb"))
);
const BasicUICards = Loadable(
  lazy(() => import("views/ui-elements/basic/UICards"))
);
const BasicUIChip = Loadable(
  lazy(() => import("views/ui-elements/basic/UIChip"))
);
const BasicUIList = Loadable(
  lazy(() => import("views/ui-elements/basic/UIList"))
);
const BasicUITabs = Loadable(
  lazy(() => import("views/ui-elements/basic/UITabs"))
);

// advance ui-elements routing
const AdvanceUIAlert = Loadable(
  lazy(() => import("views/ui-elements/advance/UIAlert"))
);
const AdvanceUIDialog = Loadable(
  lazy(() => import("views/ui-elements/advance/UIDialog"))
);
const AdvanceUIPagination = Loadable(
  lazy(() => import("views/ui-elements/advance/UIPagination"))
);
const AdvanceUIProgress = Loadable(
  lazy(() => import("views/ui-elements/advance/UIProgress"))
);
const AdvanceUIRating = Loadable(
  lazy(() => import("views/ui-elements/advance/UIRating"))
);
const AdvanceUISnackbar = Loadable(
  lazy(() => import("views/ui-elements/advance/UISnackbar"))
);
const AdvanceUISkeleton = Loadable(
  lazy(() => import("views/ui-elements/advance/UISkeleton"))
);
const AdvanceUISpeeddial = Loadable(
  lazy(() => import("views/ui-elements/advance/UISpeeddial"))
);
const AdvanceUITimeline = Loadable(
  lazy(() => import("views/ui-elements/advance/UITimeline"))
);
const AdvanceUIToggleButton = Loadable(
  lazy(() => import("views/ui-elements/advance/UIToggleButton"))
);
const AdvanceUITreeview = Loadable(
  lazy(() => import("views/ui-elements/advance/UITreeview"))
);

// pricing page routing
const PagesPrice1 = Loadable(lazy(() => import("views/pages/pricing/Price1")));
const PagesPrice2 = Loadable(lazy(() => import("views/pages/pricing/Price2")));

// utilities routing
const UtilsTypography = Loadable(
  lazy(() => import("views/utilities/Typography"))
);
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsAnimation = Loadable(
  lazy(() => import("views/utilities/Animation"))
);
const UtilsGrid = Loadable(lazy(() => import("views/utilities/Grid")));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// const NoChannelsAdded = Loadable(lazy(() => import("views/add-channels")));

const StaffONSIBO = Loadable(
  lazy(() => import("views/application/customer/Staff/Staff")));
const BusinessUnits = Loadable(
    lazy(() => import("views/application/customer/BusinessUnits"))
  );


  const CoreUnits = Loadable(
    lazy(() => import("views/application/customer/CoreUnits"))
  );

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/widget/statistics",
      element: <WidgetStatistics />,
    },
    {
      path: "/widget/data",
      element: <WidgetData />,
    },
    {
      path: "/widget/chart",
      element: <WidgetChart />,
    },

    {
      path: "/user/social-profile/:tab",
      element: <AppUserSocialProfile />,
    },
    {
      path: "/user/account-profile/profile1",
      element: <AppUserAccountProfile1 />,
    },
    {
      path: "/user/account-profile/profile2",
      element: <AppUserAccountProfile2 />,
    },
    {
      path: "/user/account-profile/profile3",
      element: <AppUserAccountProfile3 />,
    },

    {
      path: "/user/card/card1",
      element: <AppProfileCardStyle1 />,
    },
    {
      path: "/user/card/card2",
      element: <AppProfileCardStyle2 />,
    },
    {
      path: "/user/card/card3",
      element: <AppProfileCardStyle3 />,
    },
    {
      path: "/user/list/list1",
      element: <AppProfileListStyle1 />,
    },
    {
      path: "/user/list/list2",
      element: <AppProfileListStyle2 />,
    },

    {
      path: "/customer/customer-list",
      element: <AppCustomerList />,
    },
    {
      path: "/customer/order-list",
      element: <AppCustomerOrderList />,
    },
    {
      path: "/customer/create-invoice",
      element: <AppCustomerCreateInvoice />,
    },
    {
      path: "/customer/order-details",
      element: <AppCustomerOrderDetails />,
    },
    {
      path: "/customer/product",
      element: <AppCustomerProduct />,
    },
    {
      path: "/customer/product-review",
      element: <AppCustomerProductReview />,
    },

    {
      path: "/app/chat",
      element: <AppChat />,
    },
    {
      path: "/app/mail",
      element: <AppMail />,
    },
    {
      path: "/app/kanban",
      element: <AppKanban />,
      children: [
        {
          path: "backlogs",
          element: <AppKanbanBacklogs />,
        },
        {
          path: "board",
          element: <AppKanbanBoard />,
        },
      ],
    },
    {
      path: "/app/calendar",
      element: <AppCalendar />,
    },
    {
      path: "/app/contact/c-card",
      element: <AppContactCard />,
    },
    {
      path: "/app/contact/c-list",
      element: <AppContactList />,
    },

    {
      path: "/e-commerce/products",
      element: <AppECommProducts />,
    },
    ,
    

    {
      path: "/dashboard/default",
      element: <DashboardDefault />,
    },
    {
      path: "/dashboard/proposals",
      element: <AppCustomerOrderList />,
    },
    {
      path: "/dashboard/projects",
      element: <ProjectsONSIBO />,
    },
    {
      path: "/dashboard/tasks",
      element: <TasksONSIBO />,
    },
    {
      path: "/dashboard/invoices",
      element: <InvoicesONSIBO />,
    },{
      path: "/dashboard/expenses",
      element: <ExpensesONSIBO />,
    },
    {
      path: "/dashboard/properties",
      element: <PropertiesONSIBO />,
    },
    {
      path: "/dashboard/services",
      element: <ServicesONSIBO />,
    },{
      path: "/dashboard/accounts",
      element: <AccountsONSIBO />,
    },
    
    {
      path: "/dashboard/leads",
      element: <LeadsONSIBO />,
    },
    {
      path: "/dashboard/orders",
      element: <OrdersONSIBO />,
    },
    // businessUnits 
    
    // AccountsONSIBO


    {
      path: "/dashboard/proposals/i/view/:id",
      element: <ViewProposalInternal />,
    },


    {
      path: "/dashboard/invoices/i/view/:id",
      element: <ViewInvoicesInternal />,
    },


    {
      path: "/dashboard/staff",
      element: <StaffONSIBO/>,
    },
    {
      path: "/dashboard/my-business",
      element: <BusinessUnits/>,
    },
    // BusinessUnits

    // /spadmin/business
    {
      path: "/spadmin/business",
      element: <CoreUnits/>,
    },
    
  ],
};

export default MainRoutes;
