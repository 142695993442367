import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
// import axios from "axios";
// import axios from "axios";
import axios from "axios";
// third-party
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// action - state management
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";

// project imports
import Loader from "ui-component/Loader";
import { FIREBASE_API } from "config";
import { API_URL } from "config";

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_API);
}

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const getUserAccountData = (user, opType, uid) => {
  let url = "";
  console.log("GETTING PRIVATE TOKEN FOR");
  if (user.email) {
    url = API_URL + "signin";
  } else {
    url = API_URL + "signin";
  }
  console.log(url);
  const data = {
    optype: opType,
    uid: uid,
  };
  console.log("payload", data);
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        // console.log(response.data.auth_token);
        // resolve(response.data.businessData);
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        console.error("FAIL TO SET PRIVATE TOKEN");
        reject(false);
      });
  });
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);
export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          var role = 1;
          var businessData = [];
          var idToken = "";
          var response = null;
          try {
            idToken = await firebase
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true);
            var opType = "";
            var uid = idToken;
            var signup = user.metadata.creationTime;
            var signin = user.metadata.lastSignInTime;
            const idTokenResult = await user.getIdTokenResult();
            const claims = idTokenResult.claims;
            console.log(claims);

            if (signin == signup) {
              opType = "signUp";
            } else {
              opType = "signIn";
            }
            try {
              response = await getUserAccountData(user, opType, uid);
              console.log(response);
              businessData = response.businessData;
              console.log(businessData);
            } catch (error) {
              console.log("Fail to read private token");
            }
            // const response = await axios.post(API_URL+"/signin", {
            //     optype:opType,
            //     uid:uid
            // });

            // console.error(response.data);
            try {
              console.warn(response.businessData[0]);
              businessData = response.businessData[0];
              // console.log(businessData.status);
              try {
                if (businessData.status == "off") {
                  alert(
                    "Your account has been deactivated by the admin, please contact support@onsibo.com"
                  );
                  firebase.auth().signOut();
                  window.location.href = "/login";
                  return;
                }
              } catch (error) {}
            } catch (error) {
              console.error(error);
            }
            console.log("--->");
            console.log(idTokenResult);
            console.log(idTokenResult.claims);
            console.log("--->>>");
            const isAdmin = !!idTokenResult.claims.is_admin;
            const isTechnician = !!idTokenResult.claims.is_technician;
            const isSuperAdmin = !!idTokenResult.claims.is_super_admin;
            const isPropertyUser = !!idTokenResult.claims.is_property;
            // alert(isAdmin);
            // alert(isTechnician);
            
            dispatch({
              type: LOGIN,
              payload: {
                isLoggedIn: true,
                user: {
                  id: user.uid,
                  email: user.email,
                  name: user.displayName || "Usuario nuevo",
                  picurl: user.photoURL,
                  role: role,
                  businessData: businessData,
                  sessionToken: idToken,
                  is_admin: isAdmin,
                  is_technician: isTechnician,
                  is_super_admin: isSuperAdmin,
                  is_property: isPropertyUser,
                  propertyID: response.property_id
                },
              },
            });
            // console.log(response.data.role);
          } catch (error) {
            // console.error(error);
          }
          // console.log("Dispatching");
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      });
    };
    fetchData();
  }, [dispatch]);

  const firebaseEmailPasswordSignIn = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseFacebookSignIn = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope("instagram_basic"); //testing now
    provider.addScope("instagram_manage_messages"); //testing now
    provider.addScope("pages_messaging");
    provider.addScope("read_page_mailboxes");
    provider.addScope("pages_show_list");
    provider.addScope("pages_read_engagement");
    provider.addScope("pages_manage_metadata");
    let FBtoken = "";
    const signin = firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var token = result.credential.accessToken;
        var uID = result.additionalUserInfo.profile.id;
        // console.log(token);
        FBtoken = token;
        localStorage.setItem("fbauth", FBtoken);
        // localStorage.setItem("fbauth_all",JSON.stringify(result));
        localStorage.setItem("fbauthid", uID);
        return FBtoken;
      });
    return signin;
    // return result;
  };

  const firebaseRegister = async (email, password) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

  const logout = () => firebase.auth().signOut();

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        login: () => {},
        firebaseGoogleSignIn,
        firebaseFacebookSignIn,
        logout,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default FirebaseContext;
