// third-party
import { FormattedMessage } from "react-intl";
import {
  IconDashboard,
  IconDeviceAnalytics,
  IconNote,
  IconBox,
  IconSettings,
  IconOutbound,
  IconFileInvoice,
  IconFileDollar,
  IconUserCircle,
  IconFileDots,
  IconBuilding,
  IconBuildingCastle,
  IconLicense,
  IconAward,
  IconPaperBag,
} from "@tabler/icons";
import SettingsIcon from "@mui/icons-material/Settings";
// assets
import {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
} from "@tabler/icons";

// constant
const icons = {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
};

const dashboardIcon = {
  dashboard: IconDashboard,
  device: IconDeviceAnalytics,
  notes: IconNote,
  box: IconBox,
  settings: IconSettings,
  outs: IconOutbound,
  invoice: IconFileInvoice,
  account: IconFileDollar,
  staff: IconUserCircle,
  propos: IconFileDots,
  proper: IconBuildingCastle,
  svc: IconLicense,
  lead: IconAward,
  orders: IconPaperBag,
  mybusiness: SettingsIcon,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

//  {
//     path: "/dashboard/default",
//     element: <DashboardDefault />,
//   },
//   {
//     path: "/dashboard/proposals",
//     element: <AppCustomerOrderList />,
//   },
//   {
//     path: "/dashboard/projects",
//     element: <ProjectsONSIBO />,
//   },
//   {
//     path: "/dashboard/tasks",
//     element: <TasksONSIBO />,
//   },
//   {
//     path: "/dashboard/invoices",
//     element: <InvoicesONSIBO />,
//   },{
//     path: "/dashboard/expenses",
//     element: <ExpensesONSIBO />,
//   },
//   {
//     path: "/dashboard/properties",
//     element: <PropertiesONSIBO />,
//   },
//   {
//     path: "/dashboard/services",
//     element: <ServicesONSIBO />,
//   },{
//     path: "/dashboard/accounts",
//     element: <AccountsONSIBO />,
//   },

//   {
//     path: "/dashboard/leads",
//     element: <LeadsONSIBO />,
//   },
//   {
//     path: "/dashboard/orders",
//     element: <OrdersONSIBO />,
//   },

const application = {
  id: "application",
  title: <FormattedMessage id="application" />,
  icon: icons.IconApps,
  type: "group",
  children: [
    {
      id: "proposals",
      title: <FormattedMessage id="proposals" />,
      type: "item",
      icon: dashboardIcon.propos,
      url: "/dashboard/proposals",
    },

    {
      id: "projects",
      title: <FormattedMessage id="projects" />,
      type: "item",
      icon: dashboardIcon.box,
      url: "/dashboard/projects",
    },

    {
      id: "tasks",
      title: <FormattedMessage id="tasks" />,
      type: "item",
      icon: dashboardIcon.settings,
      url: "/dashboard/tasks",
    },

    {
      id: "invoices",
      title: <FormattedMessage id="invoices" />,
      type: "item",
      icon: dashboardIcon.invoice,
      url: "/dashboard/invoices",
    },

    {
      id: "expenses",
      title: <FormattedMessage id="expenses" />,
      type: "item",
      icon: dashboardIcon.outs,
      url: "/dashboard/expenses",
    },

    {
      id: "properties",
      title: <FormattedMessage id="properties" />,
      type: "item",
      icon: dashboardIcon.proper,
      url: "/dashboard/properties",
    },

    {
      id: "products",
      title: <FormattedMessage id="products" />,
      type: "item",
      icon: dashboardIcon.svc,
      url: "/dashboard/services",
    },

    {
      id: "accounts",
      title: <FormattedMessage id="accounts" />,
      type: "item",
      icon: dashboardIcon.account,
      url: "/dashboard/accounts",
    },

    {
      id: "leads",
      title: <FormattedMessage id="leads" />,
      type: "item",
      icon: dashboardIcon.lead,
      url: "/dashboard/leads",
    },

    {
      id: "orders",
      title: <FormattedMessage id="orders" />,
      type: "item",
      icon: dashboardIcon.orders,
      url: "/dashboard/orders",
    },
  ],
};

export default application;
