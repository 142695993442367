/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create();
export const Axios = axios.create({ withCredentials: true });

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || error)
);

axiosServices.interceptors.request.use(function (config) {    
    // console.log(config)
    return config;
  }, function (error) {
    // Do something with request error
    localStorage.clear();
    return Promise.reject(error);
  });

export default axiosServices;

// import axios from 'axios';
// const axiosServices = axios.create();
// // ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //
// axiosServices.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
// );
// export default axiosServices;
// // ==============================|| AXIOS - FOR REAL REQUEST ||============================== //
// export const Axios = axios.create({ withCredentials: true });